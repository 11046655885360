import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from '@aws-amplify/api';
import { getActiveFlow, getAllFlows, getFlow, getFlows, getNodes, getTags, getUserModes,getFilesForType,getTeams } from "../../graphql/queries";
import { createFlow, publishFlow, saveFlowNodes, saveNewFlowVersion} from "../../graphql/mutations";


class NodeService {
  
  getAllFlows = async (workspace: string): Promise<any> => {
    try {
      const response = await API.graphql(graphqlOperation(getAllFlows, {workspace}));
      return response ;     
    } catch (error) {
        console.error("NodeService", "getAllFlows", error);
        return error
    }
  };

  getActiveFlow = async (workspace: string, flowId: string): Promise<any> => {
    try {
      const response = await API.graphql(graphqlOperation(getActiveFlow, {workspace,flowId})) ;
      return response ;     
    } catch (error) {
        console.error("NodeService", "getActiveFlow", error);
        return undefined
    }
  };

  getFlow = async (workspace: string, flowId: string, version: number): Promise<any> => {
    try {
      const response = await API.graphql(graphqlOperation(getFlow, {workspace,flowId,version})) ;
      return response ;     
    } catch (error) {
        console.error("NodeService", "getFlow", error);
        return undefined
    }
  };

  getFlows = async (workspace: string, flowId: string): Promise<any> => {
    try {
      const response = await API.graphql(graphqlOperation(getFlows, {workspace,flowId})) ;
      return response;     
    } catch (error) {
        console.error("NodeService", "getFlows", error);
        return undefined
    }
  };

  createFlow = async (workspace: string, name: string, type: string, description: string): Promise<any> => {
    try {
      const response = await API.graphql(graphqlOperation(createFlow, {workspace,name,type,description})) ;
      return response;     
    } catch (error) {
        console.error("NodeService", "createFlow", error);
        return undefined;
    }
  };
  
  saveFlowNodes = async (workspace: string, flowId: string, version: number, elements: any[]): Promise<boolean> => {    
    try {      
      let nodes = elements.map((el)=>{          
          return {           
            nodeName: el.id,
            nodeType:  el.nodeType,
            nodeDisplay: el.type === "edge"? el.label: el.id,
            id: el.id,
            category: el.category,
            design: JSON.stringify(el),
            data: JSON.stringify({...el.data,supportMultipleNode :el.multiple , labelStyle:el.labelStyle}),
            type: el.type,
            position: {x: el.position?.x?.toString()?? "" ,y: el.position?.y?.toString()?? ""},           
            source: {point: el.source ?? "", pointHandler: el.sourceHandle ?? "", event: el.type === "edge"? el.label: el.id},
            destination: {point: el.target ?? "", pointHandler: el.targetHandle ?? "", event: el.type === "edge"? el.label: el.id}
          }
      });

      const flowNodes = {
        flowId,
        version,
        workspace,
        nodes
      };     

      const response: GraphQLResult<any> = await API.graphql({ query: saveFlowNodes, variables: {...flowNodes} }) ;
      if(response.data.saveFlowNodes.status === false)
      {
        return false;
      }
      else if(response.data.saveFlowNodes.object){
        const saveData = JSON.parse(response.data.saveFlowNodes.object);
        if(saveData.unprocessedItems?.Operational.length > 1 && (saveData.unprocessedItems?.Operational[0] !== undefined || saveData.unprocessedItems?.Operational[0] !== null)){
            return false;
        }
      }
      return true;
    } catch (error) {
        console.error("NodeService", "saveFlowNodes", error);
        return false
    }
  };

  
  saveNewFlowVersion = async (workspace: string, flowId: string, name: string): Promise<any> => {
    try {      
      const response: GraphQLResult<any> = await API.graphql({ query: saveNewFlowVersion, variables: {workspace,flowId,name} }) ;
      return response.data.saveNewFlowVersion ;     
    } catch (error) {
        console.error("NodeService", "saveNewFlowVersion", error);
        return undefined;
    }
  };

  publishFlow = async (workspace: string, flowId: string, version: number): Promise<any> => {
    try {      
      const response: GraphQLResult<any> = await API.graphql({ query: publishFlow, variables: {workspace,flowId, version} }) ;
      return response.data.publishFlow ;     
    } catch (error) {
        console.error("NodeService", "publishFlow", error);
        return undefined;
    }
  };

  getNodes = async (workspace: string, flowId: string, version: number): Promise<any> => {
    try {
      const response: GraphQLResult<any> = await API.graphql({ query: getNodes, variables: {flowId,workspace,version,} }) ;
      return response.data.getNodes?.items ;     
    } catch (error) {
        console.error("NodeService", "getNodes", error);
        return undefined ; //error?.errors[0]?.errorType?? undefined;
    }
  };


  getUserModes = async (workspace: string): Promise<any[]> => {
    let users: any[] = [];
    try {
      
      const  loadData = async(nextToken: string | undefined)=>{
        const getUserModesQueryVariables = {
          workspace,nextToken
        };
        const response: GraphQLResult<any> = await API.graphql({ query: getUserModes, variables: getUserModesQueryVariables }) ;
        const data = response.data.getUserModes ;
        
        if(data.items){
          users = [...users,...data.items];
        }
        if(data.nextToken){
          loadData(data.nextToken);
        }
      }
      await loadData(undefined);          
    } catch (error) {
        console.error("UserService", "getUserModes", error);
    }
      return users;
  
  }

  getCallTag = async (workspace: string): Promise<any[]> => {
    let tags: any[] =[];
    try {  
        
        const loadMore = async (nextToken: string)=>{
            let interaction: GraphQLResult<any> = await API.graphql({ query: getTags, variables: {workspace , limit: 100,  nextToken} }) ;
            const data = interaction.data.getTags ;
            if(data.items ){
                data.items.map((i: any) => {
                    if(i){
                        tags.push(i);
                    }
                })                   
                if(data.nextToken){
                    loadMore(data.nextToken);
                }
            }
        }          
        await loadMore("");            
    } catch (error) {
        console.error("TagService", "getCallTag", error);
    }
    return tags;
  }

  getFilesForType = async (workspace: string): Promise<{enumNames: any, enumIds: any}> => {
    let enumNames: any ={};
    let enumIds: any ={};
    let count=0;
    try {  
        
        const loadMore = async (nextToken: string)=>{
            let interaction: GraphQLResult<any> = await API.graphql({ query: getFilesForType, variables: {workspace , category:"clips",limit:100, nextToken} }) ;
            const data = interaction.data.getFilesForType ;
            if(data.items ){
                data.items.map((i: any) => {
                    if(i){
                      if(enumNames[i.display]){
                        count++;
                        enumNames[`${i.display}_${count}`]=i;
                      }else{
                        enumNames[i.display]=i;
                      }                      
                      enumIds[i.key]=i;
                    }
                })                   
                if(data.nextToken){
                    loadMore(data.nextToken);
                }
            }
        }          
        await loadMore("");            
    } catch (error) {
        console.error("TagService", "getFilesForType", error);
    }
    return {enumNames,enumIds};
  }

  getTeams = async (workspace: string): Promise<{enumNames: any, enumIds: any}> => {
    let enumNames: any ={};
    let enumIds: any ={};
    try {  
        
        const loadMore = async (nextToken: string)=>{
            let interaction = await API.graphql({ query: getTeams, variables: {workspace ,limit:1000, nextToken} }) ;
            const data = (interaction as any).data.getTeams ;
            if(data.items){
                data.items.map((i: any) => {
                  if(i && (i.enabled === true ||i.enabled === "true")){                    
                    enumNames[i.name]=i.name;                   
                    enumIds[i.name]=i.name;
                  }
                })                   
                if(data.nextToken){
                    loadMore(data.nextToken);
                }
            }
        }          
        await loadMore("");            
    } catch (error) {
        console.error("TagService", "getTeams", error);
    }
    return {enumNames,enumIds};
  }


}

export default new NodeService();