/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../app/service/types.generated';

import { api } from '../app/service/flowApi';
// module.hot?.accept();
export type PublishFlowMutationVariables = Types.Exact<{
  flowId: Types.Scalars['String']['input'];
  version: Types.Scalars['Int']['input'];
  workspace: Types.Scalars['String']['input'];
}>;


export type PublishFlowMutation = { __typename?: 'Mutation', publishFlow?: { __typename?: 'Flow', Flow_Start_Node?: string, Flow_Workspace_Index: string, activeVersion: number, createdAt: string, createdBy: string, description: string, entry: string, id: string, key: string, name: string, type: string, url: string, version: number } };


export const PublishFlowDocument = `
    mutation PublishFlow($flowId: String!, $version: Int!, $workspace: String!) {
  publishFlow(flowId: $flowId, version: $version, workspace: $workspace) {
    Flow_Start_Node
    Flow_Workspace_Index
    activeVersion
    createdAt
    createdBy
    description
    entry
    id
    key
    name
    type
    url
    version
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  // overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    PublishFlow: build.mutation<PublishFlowMutation, PublishFlowMutationVariables>({
      query: (variables) => ({ document: PublishFlowDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { usePublishFlowMutation } = injectedRtkApi;

