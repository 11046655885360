import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import nodeService from "../../service/nodeService";

export const getFilesForType = createAsyncThunk(
  "nodeConfigSlice/getFilesForType",
  async (workspace:string) => {
    try {
      console.debug("REDUX", "nodeConfigSlice", "getFilesForType");
      return await nodeService.getFilesForType(workspace);
    } catch (error) {
      return undefined;
    }
  }
);

export const getTeams = createAsyncThunk(
  "nodeConfigSlice/getTeams",
  async (workspace:string) => {
    try {
      console.debug("REDUX", "nodeConfigSlice", "getTeams");
      return await nodeService.getTeams(workspace);
    } catch (error) {
      return undefined;
    }
  }
);

const initialState = {
  value: 0,
  node:undefined,
  payload:undefined,
  connectedEdge:undefined,
  disconnectedEdge:undefined,
  nodeData:undefined,
  restoredElements : [],
  removedItems:[],
  configurationData:{},
  flowDetails:{},
}


export const nodeConfigSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    addNode: (state, action) => {
      state.node = action.payload
    },
    showPanel: (state, action) => {
        state.payload = action.payload;
    },
    onNodeConnected: (state, action) => {
      state.connectedEdge = action.payload
    },
    onNodeDicConnected: (state, action) => {
      state.disconnectedEdge = action.payload
    },
    onNodeDataUpdate:(state, action)=>{
      state.nodeData = {...action.payload.nodeData}
    },
    onFlowRestored:(state, action)=>{
      state.restoredElements = {...action.payload.elements}
    },
    onNodeRemoved:(state, action)=>{
      state.removedItems = {...action.payload.elements}
    },
    onSetFlowDetails:(state, action)=>{
      state.flowDetails = {...action.payload}
    },
    onVersionChanged:(state, action)=>{
      state.flowDetails = {...state.flowDetails,version:action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilesForType.fulfilled, (state:any, action:any) => {
      if(action.payload){
        state.configurationData["ivr"] = action.payload;
      }      
    })
    builder.addCase(getTeams.fulfilled, (state: any, action: any) => {
      if(action.payload){
       state.configurationData["teams"] = action.payload;
      }      
    })
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, addNode ,showPanel,onNodeConnected,onNodeDicConnected,onNodeDataUpdate,onFlowRestored,onNodeRemoved,onSetFlowDetails,onVersionChanged} = nodeConfigSlice.actions

export default nodeConfigSlice.reducer