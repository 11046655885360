import React, { useState, useEffect } from 'react';
import { decodeToken } from './../service/workspacesService';
import { useNavigate } from 'react-router-dom';
import Button from "antd/lib/button";
import Loading from "./../components/Loading";
import Layout from "antd/lib/layout";
import { Header } from "antd/lib/layout/layout";
import { Settings } from "./Settings";
import PropTypes from "prop-types";

interface WorkspacesProps {
  signOut: () => void;
}

const Workspaces: React.FC<WorkspacesProps> = (props) => {
  const navigate = useNavigate();
  const [decodedToken, setDecodedToken] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const decoded = await decodeToken();
        setDecodedToken(decoded);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ width: "100vw", backgroundColor: "#E9E6FF" }}>
      {/* @ts-expect-error TS(2322): Type '{ children: Element; theme: string; }' is no... Remove this comment to see the full error message */}
      <Layout theme="light" >
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Settings toggle={toggle} collapsed={collapsed} signOut={props.signOut} />
        </Header>
      </Layout>
      <div style={styles.container as React.CSSProperties}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <h1 style={{ textAlign: 'center' }}>Workspaces</h1>
            {decodedToken ? (
              <div>
                <h2>{decodedToken.email}</h2>
                {decodedToken.tenant.split(',').map((tenant: string, index: number) => {
                  const tenantName = tenant.split('@')[1];
                  return (
                    <Button key={index} style={styles.button} onClick={() => navigate(`${tenantName}`, { replace: true })}>
                      {tenantName}
                    </Button>
                  );
                })}
              </div>
            ) : (
              <p>No JWT token decoded yet.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#E9E6FF',
    maxWidth: '800px',
    height: '100vh',
    textAlign: 'center',
  },
  button: {
    color: '#573BFF',
    width: '100%',
    borderColor: '#573BFF',
    margin: '10px 0',
  },
};

Workspaces.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default Workspaces;
