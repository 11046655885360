import React, { Suspense, useEffect } from "react";
import PropTypes from 'prop-types';
import AppRouter from "./routes/AppRouter";
import Loading from "./app/components/Loading";
import { useDispatch } from "react-redux";
import { getMyProfile } from "./app/redux/features/userSlice";
import { Action, Dispatch } from "@reduxjs/toolkit";

interface User {
  user: {};
}

interface AppProps {
  signOut: () => void;
}

export const UserContext = React.createContext<User>({ user: {} });

const App: React.FC<AppProps> = (props) => {
  const dispatch: Dispatch<Action> = useDispatch();

  useEffect(() => {
    dispatch(getMyProfile() as Array<Action> | any);
  }, [dispatch]);

  return (
    <Suspense fallback={<Loading />}>
      <AppRouter signOut={props.signOut} />
    </Suspense>
  );
}

App.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default App;