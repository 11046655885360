import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "antd/lib/button";
import Loading from "./../components/Loading";
import Layout from "antd/lib/layout";
import { Header } from "antd/lib/layout/layout";
import {Settings} from "./Settings"; // Assuming Settings is a default export from "./Settings"
import PropTypes from "prop-types";
import { useGetAllFlowsQuery } from '../../graphql/getAllFlows.generated';

interface IvrFlowsProps {
  signOut: () => void;
}

const IVRFlows: React.FC<IvrFlowsProps> = (props) => {
  const { tenantName } = useParams();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const formatDate = (dateString: any) => {
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleString("en-US", {
      ...options,
    });
    return formattedDate;
  };

  // Use the generated hook for fetching flows
  const { data: flowsData, error: flowsError } = useGetAllFlowsQuery({
    workspace: tenantName || "",
  });

  if (flowsError) {
    console.error("Error fetching flows:", flowsError);
    return <div>Error fetching flows</div>;
  }

  const flows = flowsData?.getAllFlows?.items || [];

  return (
    <div style={{ width: "100vw", backgroundColor: "#E9E6FF", margin:0}}>
      {/* @ts-expect-error TS(2322): Type '{ children: Element; theme: string; }' is no... Remove this comment to see the full error message */}
      <Layout  theme="light">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Settings toggle={toggle} collapsed={collapsed} signOut={props.signOut} />
        </Header>
      </Layout>
      <div style={styles.container as React.CSSProperties}>
        <>
          <h2 style={{ marginTop: "10px" }}>IVR Flows - {tenantName}</h2>
          <Button style={styles.button_header as React.CSSProperties}>
            <p style={styles.text}>Flow Name</p>
            <p style={styles.text}>versions</p>
            <p style={styles.text}>createdDate</p>
          </Button>
          {flows.map((flow: any) => (
            <Button
              style={styles.button as React.CSSProperties}
              onClick={() => navigate(`flow/${flow.id}`, { replace: true })}
              key={flow.id}
            >
              <p style={styles.text}>{flow.name}</p>
              <p style={styles.text}>{flow.version}</p>
              <p style={styles.text}>{formatDate(flow.createdAt)}</p>
            </Button>
          ))}
        </>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#E9E6FF",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    paddingBlock: "20px",
  },
  button: {
    position: "relative",
    display: "flex",
    color: '#573BFF',
    width: 'auto',
    borderColor: '#573BFF',
    paddingInline: '10px',
    marginInline: 'auto',
    marginBlock: '5px',
    paddingBlock: '0px',
    height: 'auto',
    borderRadius: '5px',
  },
  button_header: {
    position: "relative",
    display: "flex",
    color: '#FFFFFF',
    width: 'auto',
    borderColor: '#573BFF',
    paddingInline: '10px',
    marginInline: 'auto',
    marginBlock: '5px',
    paddingBlock: '0px',
    height: 'auto',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: '#573BFF'
  },
  text: {
    marginInline: "100px",
    width: '200px'
  }
};

IVRFlows.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default IVRFlows;
