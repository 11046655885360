// import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
// import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";
// import MenuFoldOutlined from "@ant-design/icons/lib/icons/MenuFoldOutlined";
// import MenuUnfoldOutlined from "@ant-design/icons/lib/icons/MenuUnfoldOutlined";
// import Text  from "antd/lib/typography/Text";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Meta from "antd/lib/card/Meta";
import Col from "antd/lib/col";
import Image from "antd/lib/image";
//import Divider from "antd/lib/divider";
// import Dropdown from "antd/lib/dropdown";
//import Menu from "antd/lib/menu";
import Row from "antd/lib/row";
import Avatar from "antd/lib/avatar";
import React, { useContext,useEffect } from "react";
import { UserContext } from "../../App";
import "./Settings.scss";
import { useSelector } from "react-redux"; //removed useDispatch because it was not used
//import {onSelectWorkspace} from "../redux/features/userSlice";
import notification from "../../assets/images/icons/notification.svg";
import logout from "../../assets/images/icons/logout.svg";
import {NavigateFunction, useNavigate } from "react-router-dom"; //removed useLocation because it was not used
import logo from "../../assets/images/logo.svg"
import { message } from "antd";
interface SettingsProps {
  toggle: () => void;
  collapsed: boolean;
  signOut: () => void;
}

export const Settings: React.FC<SettingsProps> = () => {//removed props because it was not used
  const context:any = useContext(UserContext);
  const navigate:NavigateFunction = useNavigate();

  const user:any = useSelector((state:any)=> state.user);
  //commented below becuase it was not used
  //const dispatch = useDispatch();

  //commented below because it was not used
 /* const setting_menu = (
    <Menu className="btn"> 
        <Divider orientation="left" orientationMargin={5}> <Text type="secondary">Switch workspace</Text> </Divider>
          {
            user.workspaces.filter((w)=>user.currentWorkspace !== w).map((w)=>
              <Menu.Item key="logout" onClick={()=>dispatch(onSelectWorkspace({workspace:w}))}>
                <Button type="link" size="small">{w}</Button>
              </Menu.Item>
            )
          }          
        <Divider />        
          <Menu.Item key="logout" icon={<LogoutOutlined />} >
              <Button onClick={()=>navigate({pathname:"/logout"})} type="link" size="small"> Sign Out</Button>
          </Menu.Item>
    </Menu>
  );*/

  useEffect(()=>{   
    if(user.workspaces && user.workspaces.length > 0 && user.currentWorkspace){
      const index = user.workspaces.findIndex((w: any) => w === user.currentWorkspace);
      if(index < 0){
        console.error("unable to find selected workspace name in workspace list");
        message.error("unable to find selected workspace in workspace list");
        navigate({pathname:"/logout"});
      } 
    }     
  },[user.workspaces,user.currentWorkspace]);

  return (
    <Row>
      <Col className="logo-header" span={12}>
        <Image  src={logo}  preview={false}/>
      </Col>
      <Col span={12}>
        <Row className="setting">
          <Col>
            <Avatar size={25} src={notification} />  
          </Col>
          <Col>
            <div >
              <Card >
                    <Meta 
                      avatar={
                        <Avatar size={30} src="https://joeschmoe.io/api/v1/random" />
                      }
                      title={user?.currentWorkspace}
                      description={context?.user?.attributes?.email}
                    />
                    
              </Card>
            </div>           
          </Col>
          <Col>
            {/* <Button type="text" icon={<Avatar size={25} src={logout} />} onClick={()=>navigate({pathname:"/logout"})}></Button> */}
            <Button type="text" icon={<Avatar size={25} src={logout} />} disabled></Button>
          </Col>
        </Row>    
      </Col>     
    </Row>
  );
};
