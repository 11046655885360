/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../app/service/types.generated';

import { api } from '../app/service/flowApi';
// module.hot?.accept();
export type GetNodesQueryVariables = Types.Exact<{
  flowId: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nextToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
  version: Types.Scalars['String']['input'];
  workspace: Types.Scalars['String']['input'];
}>;


export type GetNodesQuery = { __typename?: 'Query', getNodes?: {
  [x: string]: any; __typename?: 'FlowNodeConnector', nextToken?: string, items?: Array<{ __typename?: 'FlowNode', Flow_Node_Edge_Source?: string, Flow_Node_Edge_Target?: string, Flow_Node_Source_Entry?: string, Flow_Node_Source_Index?: string, category: string, data: string, design: string, entry: string, flowId: string, id: string, key: string, nodeDisplay?: string, nodeName: string, nodeType?: string, type: string, destination?: { __typename?: 'FlowEdge', event: string, point: string, pointHandler: string }, position?: { __typename?: 'Position', x: string, y: string }, source?: { __typename?: 'FlowEdge', event: string, point: string, pointHandler: string } }> 
} };


export const GetNodesDocument = `
    query GetNodes($flowId: String!, $limit: Int, $nextToken: String, $version: String!, $workspace: String!) {
  getNodes(
    flowId: $flowId
    limit: $limit
    nextToken: $nextToken
    version: $version
    workspace: $workspace
  ) {
    items {
      Flow_Node_Edge_Source
      Flow_Node_Edge_Target
      Flow_Node_Source_Entry
      Flow_Node_Source_Index
      category
      data
      design
      destination {
        event
        point
        pointHandler
      }
      entry
      flowId
      id
      key
      nodeDisplay
      nodeName
      nodeType
      position {
        x
        y
      }
      source {
        event
        point
        pointHandler
      }
      type
    }
    nextToken
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  // overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetNodes: build.query<GetNodesQuery, GetNodesQueryVariables>({
      query: (variables) => ({ document: GetNodesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetNodesQuery, useLazyGetNodesQuery } = injectedRtkApi;

