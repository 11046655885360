import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import { Amplify, Auth } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import { Flow } from './types.generated';

// Configure Amplify
(Amplify as any).configure(awsmobile);



// Function to get the Cognito User Token
const getCognitoToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
    
  } catch (error) {
    console.error('Error getting Cognito token:', error);
    throw error;
  }
};

const client: GraphQLClient = new GraphQLClient(awsmobile.aws_appsync_graphqlEndpoint);


export const api = createApi({
  
  baseQuery: graphqlRequestBaseQuery({
    // @ts-expect-error TS(2741): Property 'options' is missing in type 'import("D:/... Remove this comment to see the full error message
    client,
    prepareHeaders: async (headers) => {
      const jwtToken = await getCognitoToken();
      if (jwtToken) {
        headers.set('Authorization', `${jwtToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
