import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./features/counter/counterSlice";
import nodeConfigReducer from "./features/nodeConfigSlice";
import userSlice from "./features/userSlice";
// import {flowsApi} from "./features/flowsSlice"
import { api } from './../service/flowApi';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    nodeConfig: nodeConfigReducer,
    user:userSlice,
    [api.reducerPath]: api.reducer,
  },
  
  middleware: (getDefaultMiddleware:any) =>
    getDefaultMiddleware().concat(api.middleware), // Add flowsApi.middleware
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
