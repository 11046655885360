import PropType from "prop-types";
import Layout from "antd/lib/layout";
//import Image from "antd/lib/image";
import { Content, Header ,Footer} from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React, { Suspense, useEffect, useState } from "react";
import Loading from "../components/Loading";
import LeftSider from "./LeftSider";
import { Outlet,useParams } from "react-router-dom";
import { Settings } from "./Settings";
import packageInfo from "../../../package.json";
import { useDispatch } from "react-redux";//removed useSelector because it was not used
import {onSelectWorkspace} from "../redux/features/userSlice";

interface ConsoleProps {
  signOut: () => void;
}

const Console: React.FC<ConsoleProps> = (props) => {
  
  const { workspace } = useParams();
  const dispatch = useDispatch();

  const [collapsed,setCollapsed] = useState<boolean>(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(()=>{    
    dispatch(onSelectWorkspace({workspace}))
  });

  return (
    <Suspense fallback={<Loading />}>
      {/* @ts-expect-error TS(2322): Type '{ children: Element; theme: string; }' is no... Remove this comment to see the full error message */}
      <Layout theme="light">
        <Header className="site-layout-background" style={{ padding: 0 }}>            
              <Settings toggle={toggle} collapsed={collapsed} signOut={props.signOut}/>
        </Header>
        <Layout>
          <Sider theme="light"  trigger={null} collapsible collapsed={collapsed}>            
            <LeftSider/>
          </Sider>
          <Content
            className="site-layout-background"
            style={{
              margin: '10px',
              padding: 24,
              minHeight: "90vh",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
        <Footer style={{ textAlign: 'center' ,paddingTop:"2px" , paddingBottom:"2px" , background: '#ffffff',  margin: '0px 16px'}}>Flow Designer ©2022 -{packageInfo.version}</Footer>  
      </Layout>
    </Suspense>
  );
}
// used to validate the type of props passed to the component
Console.propTypes = {
  signOut: PropType.func.isRequired,
};

export default Console;
