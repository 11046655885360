import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { UserContext } from "./App";
import { store } from "./app/redux/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import Loading from "./app/components/Loading";
import { CookieStorage } from "amazon-cognito-identity-js";
import "antd/dist/antd.js";
import AppRouter from "./routes/AppRouter";

let isAuthenticated:boolean = false;

Amplify.configure(
  Object.assign(
    {
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
      graphql_headers: async () => {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        // console.log("JWT Token:", jwtToken);
                isAuthenticated = true;
        console.log("Is Authenticated", isAuthenticated);
        return {
          Authorization: jwtToken,
        };
      },
      storage: getDomain(),
    },
    awsExports
  )
);

function getDomain() {
  if (
    process.env.REACT_APP_COOKIE_DOMAIN &&
    window.location.hostname !== "localhost"
  ) {
    return new CookieStorage({
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: false,
    });
  }
}



const AppWrapper: React.FC = () => (
  <Suspense fallback={<Loading />}>
    <Provider store={store}>
        <Authenticator hideSignUp loginMechanisms={["email"]}>
          {( user ) => (
            <UserContext.Provider value={{ user }}>
              <AppRouter signOut={()=>{} } />
            </UserContext.Provider>
          )}
        </Authenticator>
    </Provider>
  </Suspense>
);


const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<AppWrapper />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
