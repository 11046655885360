/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../app/service/types.generated';

import { api } from '../app/service/flowApi';
// module.hot?.accept();
export type GetFlowsQueryVariables = Types.Exact<{
  flowId: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nextToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
  workspace: Types.Scalars['String']['input'];
}>;


export type GetFlowsQuery = { __typename?: 'Query', getFlows?: { __typename?: 'FlowDetailConnector', nextToken?: string, flow: { __typename?: 'Flow', Flow_Start_Node?: string, Flow_Workspace_Index: string, activeVersion: number, createdAt: string, createdBy: string, description: string, entry: string, id: string, key: string, name: string, type: string, url: string, version: number }, versions?: Array<{ __typename?: 'FlowVersion', Flow_Start_Node?: string, createdAt: string, createdBy: string, entry: string, key: string, name: string, url?: string, version?: number }> } };


export const GetFlowsDocument = `
    query GetFlows($flowId: String!, $limit: Int, $nextToken: String, $workspace: String!) {
  getFlows(
    flowId: $flowId
    limit: $limit
    nextToken: $nextToken
    workspace: $workspace
  ) {
    flow {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      key
      name
      type
      url
      version
    }
    nextToken
    versions {
      Flow_Start_Node
      createdAt
      createdBy
      entry
      key
      name
      url
      version
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  // overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetFlows: build.query<GetFlowsQuery, GetFlowsQueryVariables>({
      query: (variables) => ({ document: GetFlowsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetFlowsQuery, useLazyGetFlowsQuery } = injectedRtkApi;

