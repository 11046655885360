import React, { FC } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import IVR from "../app/components/Nodes";
import Logout from "../app/layout/Logout";
import PropTypes from "prop-types";
import Console from "../app/layout/Console";
import { Navigate } from "react-router";
import Workspaces from "../app/layout/Workspaces";
import IVRFlows from "../app/layout/IVRFlows";

interface AppRouterProps {
  signOut: () => void;
}

const AppRouter: FC<AppRouterProps> = (props) => {
  console.log("AppRouter component is rendered.");
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/console" />} />
        <Route path="/console" element={<Workspaces signOut={function (): void {
          throw new Error("Function not implemented.");
        } } />} />
        <Route path="/console/:tenantName" element={<IVRFlows signOut={function (...args: any[]) {
          throw new Error("Function not implemented.");
        } } />}>
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/console/:workspace"
          element={<Console signOut={props.signOut} />}
        >
          <Route path="flow/:flowId" element={<IVR />} />
        </Route>
        <Route path="*" element={<Navigate to="/console" />}></Route>
      </Routes>
    </Router>
  );
};

// used to validate the type of props passed to the component
AppRouter.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default AppRouter;