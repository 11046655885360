/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../app/service/types.generated';

import { api } from '../app/service/flowApi';
// module.hot?.accept();
export type SaveNewFlowVersionMutationVariables = Types.Exact<{
  firstNode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  flowId: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
  workspace: Types.Scalars['String']['input'];
}>;


export type SaveNewFlowVersionMutation = { __typename?: 'Mutation', saveNewFlowVersion?: { __typename?: 'FlowVersion', Flow_Start_Node?: string, createdAt: string, createdBy: string, entry: string, key: string, name: string, url?: string, version?: number } };


export const SaveNewFlowVersionDocument = `
    mutation SaveNewFlowVersion($firstNode: String, $flowId: String!, $name: String!, $workspace: String!) {
  saveNewFlowVersion(
    firstNode: $firstNode
    flowId: $flowId
    name: $name
    workspace: $workspace
  ) {
    Flow_Start_Node
    createdAt
    createdBy
    entry
    key
    name
    url
    version
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  // overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SaveNewFlowVersion: build.mutation<SaveNewFlowVersionMutation, SaveNewFlowVersionMutationVariables>({
      query: (variables) => ({ document: SaveNewFlowVersionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSaveNewFlowVersionMutation } = injectedRtkApi;

