import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopCircle } from "@fortawesome/free-solid-svg-icons";
import Handler from "./Handler";

const EndNode: React.FC  = () => {
  return (
  <div title="End">
       <FontAwesomeIcon icon={faStopCircle} className="svg" />
       <Handler index={"end"} type={"target"} position={"left"} id={`end`} edge={undefined} className={"input"}/>
    </div>
  );
};

export default EndNode;
