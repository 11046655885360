/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../app/service/types.generated';

import { api } from '../app/service/flowApi';
// module.hot?.accept();
export type GetAllFlowsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nextToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
  workspace: Types.Scalars['String']['input'];
}>;


export type GetAllFlowsQuery = { __typename?: 'Query', getAllFlows?: { __typename?: 'FlowConnector', nextToken?: string, items?: Array<{ __typename?: 'Flow', Flow_Start_Node?: string, Flow_Workspace_Index: string, activeVersion: number, createdAt: string, createdBy: string, description: string, entry: string, id: string, key: string, name: string, type: string, url: string, version: number }> } };


export const GetAllFlowsDocument = `
    query GetAllFlows($limit: Int, $nextToken: String, $workspace: String!) {
  getAllFlows(limit: $limit, nextToken: $nextToken, workspace: $workspace) {
    items {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      key
      name
      type
      url
      version
    }
    nextToken
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  // overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetAllFlows: build.query<GetAllFlowsQuery, GetAllFlowsQueryVariables>({
      query: (variables) => ({ document: GetAllFlowsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAllFlowsQuery, useLazyGetAllFlowsQuery } = injectedRtkApi;

