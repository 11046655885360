import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { onNodeConnected } from "../../../redux/features/nodeConfigSlice";

const Handler = ({
  index,
  type,
  position,
  id,
  edge,
  className
}: any) => {
  const dispatch = useDispatch();
  const nodeConfig = useSelector((state: any)=>state.nodeConfig);
  const [isConnectable,setIsConnectable] = useState(true);
  useEffect(()=>{
    if(nodeConfig.connectedEdge?.sourceHandle === id){
      setIsConnectable(false);
    }
  },[id, nodeConfig.connectedEdge])

  useEffect(()=>{
    if(nodeConfig.disconnectedEdge?.sourceHandle === id){
      setIsConnectable(true);
    }
  },[id, nodeConfig.disconnectedEdge])

  const getPosition = (index: any,count: any)=>{
    try {
        const y = (100/(count + 1)) * (index + 1);
       return `${y}%`
    } catch (error) {
      // Handle errors here if necessary
    }
  }
 
  return(
    <Handle isConnectable={isConnectable} onConnect={(params) => dispatch(onNodeConnected(params))}  type={type} position={position} className={className} id={id} key={id} style={{ top:  getPosition(index,edge) , cursor: `${isConnectable? "crosshair":"not-allowed"}` }}/>
  )
};

// PropTypes validation
Handler.propTypes = {
  index: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  edge: PropTypes.number,
  className: PropTypes.string.isRequired,
};


export default Handler;
