/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../app/service/types.generated';

import { api } from '../app/service/flowApi';
// module.hot?.accept();
export type SaveFlowNodesMutationVariables = Types.Exact<{
  flowId: Types.Scalars['String']['input'];
  nodes: Array<Types.InputMaybe<Types.FlowNodeInput>> | Types.InputMaybe<Types.FlowNodeInput>;
  version: Types.Scalars['Int']['input'];
  workspace: Types.Scalars['String']['input'];
}>;


export type SaveFlowNodesMutation = { __typename?: 'Mutation', saveFlowNodes?: { __typename?: 'Response', error?: any, message: string, object?: any, status: boolean } };


export const SaveFlowNodesDocument = `
    mutation SaveFlowNodes($flowId: String!, $nodes: [FlowNodeInput]!, $version: Int!, $workspace: String!) {
  saveFlowNodes(
    flowId: $flowId
    nodes: $nodes
    version: $version
    workspace: $workspace
  ) {
    error
    message
    object
    status
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  // overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SaveFlowNodes: build.mutation<SaveFlowNodesMutation, SaveFlowNodesMutationVariables>({
      query: (variables) => ({ document: SaveFlowNodesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSaveFlowNodesMutation } = injectedRtkApi;

