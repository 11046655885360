import { API, graphqlOperation} from "aws-amplify";
import { getMyUserProfile } from "../../graphql/queries";

interface GraphQLResult<T> {
  data?: T;
  errors?: [any];
}
export default class UserService {
  static getMyProfile = async (): Promise<any> => {
    try {
      const result = await API.graphql(graphqlOperation(getMyUserProfile)) as GraphQLResult<any>;
      return result;
    } catch (error) {
      console.error("UserService", "getMyProfile", error);
      return undefined;
    }
  }
}